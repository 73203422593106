import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  PropsInitialStatePhotoSlice,
  IPhoto,
  PropsArrPhotos,
  PropsBoolean,
  PropsRandomPhotos,
  PropsAddNewPhoto,
  PropsDeletePhoto,
  ICategory,
} from "../../types";

import { apiApp } from "../../utils/apiApp";

export const fetchPhotos = createAsyncThunk(
  "photo/fetchPhotos",
  async (arg: { type: string | null; order: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiApp().getArrPhotos(
        `${
          arg.type === null
            ? "?type=newborn&type=baby&type=family&type=pregnancy&type=christening"
            : `?type=${arg.type}`
        }`
      );
      if (arg.order === "random") {
        dispatch(handlerRandomPhotos({ arr: res.data, n: res.data.length }));
      } else {
        dispatch(handlerSortPhotos(res.data));
      }
      return res.data;
    } catch (e) {
      return rejectWithValue("Error, the photos were not loaded!");
    }
  }
);
export const getPhotoCategories = createAsyncThunk("photo/getPhotoCategories", async (_, { rejectWithValue }) => {
  try {
    const res = await apiApp().getPhotoCategories();
    return res.data;
  } catch (e) {
    return rejectWithValue("Error, photo category has not been get!");
  }
});

export const addNewPhoto = createAsyncThunk(
  "photo/addNewPhoto",
  async (data: { type: string; image: string; order: number }, { rejectWithValue }) => {
    try {
      const res = await apiApp().uploadPhoto(data);
      return res.data;
    } catch (e) {
      return rejectWithValue("Error, the photo was not loaded!");
    }
  }
);

export const deletePhoto: any = createAsyncThunk(
  "photo/deletePhoto",
  async (data: PropsDeletePhoto, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiApp().deletePhoto(data.photoId);
      if (res) {
        dispatch(fetchPhotos({ type: data.type, order: data.path }));
      }
      return res.data;
    } catch (e) {
      return rejectWithValue("Error, the photo was not delete");
    }
  }
);

export const saveChangeSortPhotos: any = createAsyncThunk(
  "photo/saveChangeSortPhotos",
  async (newArr: IPhoto[], { rejectWithValue }) => {
    try {
      const res = apiApp().changeOrderPhoto(newArr);
      return res.data;
    } catch (e) {
      return rejectWithValue("Error, the sort was not a happen");
    }
  }
);

const initialState: PropsInitialStatePhotoSlice = {
  getPhotos: [],
  showPhotos: [],
  categoryPhotosBtn: null,
  loading: false,
  error: "",
  openModalWithImage: false,
  dataForImageModal: "",
  openChangeSortPhotos: false,
  photoCategories: [],
};

const photoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {
    handlerDataImageForModal: (state, action: { payload: string }): void => {
      state.dataForImageModal = action.payload;
    },
    handlerModalWithImage: (state, action: PropsBoolean): void => {
      state.openModalWithImage = action.payload;
    },
    handlerActiveCategoryPhotosBtn: (state, action: { payload: string | null }): void => {
      state.categoryPhotosBtn = action.payload;
    },
    handlerRandomPhotos: (state, action: PropsRandomPhotos): void => {
      let randomN: IPhoto[] = [];
      while (randomN.length < action.payload.n) {
        const randomIndex = Math.floor(Math.random() * action.payload.arr.length);
        randomN = [...randomN, action.payload.arr[randomIndex]];
        action.payload.arr.splice(randomIndex, 1);
      }

      const countPhotos = (arrItem: IPhoto[]): IPhoto[] => {
        if (window.innerWidth >= 1025) {
          return arrItem.slice(0, 12);
        }
        if (window.innerWidth > 768) {
          return arrItem.slice(0, 10);
        }
        if (window.innerWidth > 568) {
          return arrItem.slice(0, 8);
        }
        if (window.innerWidth >= 320) {
          return arrItem.slice(0, 6);
        }
        return arrItem;
      };
      state.showPhotos = countPhotos(randomN);
    },
    handlerSortPhotos: (state, action: PropsArrPhotos) => {
      // console.log(action.payload)
      const sortArr = action.payload.sort((a: any, b: any) => (a.order > b.order ? -1 : 0));
      // sortArr.sort((a: any, b: any) => b.order - a.order);

      const countSortPhotos = (arrItem: IPhoto[]): IPhoto[] => {
        if (window.innerWidth >= 1025) {
          return arrItem.slice(0, 16);
        }
        if (window.innerWidth > 768) {
          return arrItem.slice(0, 12);
        }
        if (window.innerWidth > 568) {
          return arrItem.slice(0, 8);
        }
        if (window.innerWidth >= 320) {
          return arrItem.slice(0, 6);
        }
        return arrItem;
      };
      state.showPhotos = countSortPhotos(sortArr);
    },

    handlerShowAddPhotos: (state, action: PropsArrPhotos) => {
      state.showPhotos = action.payload;
    },
    handlerOpenChangeSortPhotos: (state, action: { payload: boolean }) => {
      state.openChangeSortPhotos = action.payload;
    },
    changeOrderPhoto: (state, action: { payload: { id: string; duration: string } }) => {
      const arr = state.showPhotos;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]._id === action.payload.id) {
          let tmp;
          if (action.payload.duration.includes("down") && i !== arr.length - 1) {
            tmp = arr[i];
            arr[i] = arr[i + 1];
            arr[i + 1] = tmp;
            break;
          }
          if (action.payload.duration.includes("up") && i !== 0) {
            tmp = arr[i];
            arr[i] = arr[i - 1];
            arr[i - 1] = tmp;
            break;
          }
        }
      }
      state.showPhotos = arr;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhotos.pending, (state): void => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchPhotos.fulfilled, (state, action: { payload: IPhoto[] }): void => {
      state.getPhotos = action.payload;
      state.error = "";
      state.loading = false;
    });
    builder.addCase(fetchPhotos.rejected, (state, action: { payload: any }): void => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getPhotoCategories.fulfilled, (state, action: { payload: ICategory[] }) => {
      state.photoCategories = action.payload;
    });
  },
});

export const {
  handlerRandomPhotos,
  handlerActiveCategoryPhotosBtn,
  handlerModalWithImage,
  handlerDataImageForModal,
  handlerSortPhotos,
  handlerShowAddPhotos,
  changeOrderPhoto,
  handlerOpenChangeSortPhotos,
} = photoSlice.actions;
export default photoSlice.reducer;
