import "./Spinner.scss";
import React from "react";

const Spinner: React.FC = () => {
  return (
    <div id="fountainG">
      <div id="fountainG_1" className="fountainG"></div>
      <div id="fountainG_2" className="fountainG"></div>
      <div id="fountainG_3" className="fountainG"></div>
      <div id="fountainG_4" className="fountainG"></div>
      <div id="fountainG_5" className="fountainG"></div>
      <div id="fountainG_6" className="fountainG"></div>
      <div id="fountainG_7" className="fountainG"></div>
      <div id="fountainG_8" className="fountainG"></div>
    </div>
  );
};

export default Spinner;
